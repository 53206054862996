import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

enum Cities {
  JAUERLING = "jauerling",
  GASCHURN = "gaschurn",
  STANTONARLBERG = "stantonarlberg",
  GALZIGARLBERG = "galzigarlberg",
  TANNHEIM = "tannheim",
  NAUDERS = "nauders",
  EHRWALD = "ehrwald",
  PATSCHERKOFEL = "patscherkofel",
  OBERGURGL = "obergurgl",
  BRENNER = "brenner",
  HAHNENKAMM = "hahnenkamm",
  KRIMML = "krimml",
  RUDOLFSHUETTE = "rudolfshuette",
  BADGASTEIN = "badgastein",
  SONNBLICK = "sonnblick",
  STMICHAELLUNGAU = "stmichaellungau",
  OBERTAUERN = "obertauern",
  FEUERKOGEL = "feuerkogel",
  AIGENIMENNSTAL = "aigenimennstal",
  STOLZALPE = "stolzalpe",
  ZELTWEG = "zeltweg",
  LUNZ = "lunz",
  MARIAZELL = "mariazell",
  FISCHBACH = "fischbach",
  RAXSEILBAHN = "raxseilbahn",
  MOENICHKIRCHEN = "moenichkirchen",
  MITTELBERG = "mittelberg",
  MALLNITZ = "mallnitz",
  VILLACHERALPE = "villacheralpe",
  PREITENEGG = "preitenegg",
  NEUMARKTERSATTEL = "neumarktersattel",
  SCHOECKL = "schoeckl",
  VIRGEN = "virgen",
  MALLNITZBAD = "mallnitzbad",
  WEISSENSEEGATSCHACH = "weissenseegatschach",
  WARTH = "warth",
  ISCHGLIDALPE = "ischglidalpe",
  GALTUER = "galtuer",
  HOLZGAU = "holzgau",
  STLEONHARDPITZTAL = "stleonhardpitztal",
  LOFERERALM = "lofereralm",
  SCHMITTENHOEHE = "schmittenhoehe",
  RAURIS = "rauris",
  MARIAPFARR = "mariapfarr",
  RAMSAUDACHSTEIN = "ramsaudachstein",
  BADAUSSEE = "badaussee",
  SEMMERING = "semmering",
  HIRSCHENKOGELSEMMERING = "hirschenkogelsemmering",
  HOHEWAND = "hohewand",
  TURRACHERHOEHE = "turracherhoehe",
  HOCHSCHWABEISMAUER = "hochschwabeismauer",
  PLANNERALMROTBUEHEL = "planneralmrotbuehel",
  HOHETAUERN = "hohetauern",
  UNTERKAERNTEN = "unterkaernten",
  LUNGAU = "lungau",
  MONTAFON = "montafon",
  FLACHAU = "flachau",
  GROSSARL = "grossarl",
  SOELDEN = "soelden",
  KAPPL = "kappl",
  KAUNERTAL = "kaunertal",
  PFUNDS = "pfunds",
  SEE = "see",
  SERFAUS = "serfaus",
  SPISS = "spiss",
  LECH = "lech",
  DAMUELS = "damuels",
  // INNSBRUCK = "innsbruck",
  // BOZEN = "bozen",
  // LIENZ = "lienz",
}

type DustParams = {
  [key in Cities]?: Array<Promise<string[]>>;
};

@Injectable()
export class GetDustParamService {
  private pxOfCity = {
    jauerling:  {
      x:278,
      y:162,
    },
    gaschurn:  {
      x:278,
      y:162,
    },
    stantonarlberg:  {
      x:278,
      y:162,
    },
    galzigarlberg:  {
      x:278,
      y:162,
    },
    tannheim:  {
      x:278,
      y:162,
    },
    nauders:  {
      x:278,
      y:162,
    },
    ehrwald:  {
      x:278,
      y:162,
    },
    patscherkofel:  {
      x:278,
      y:162,
    },
    obergurgl:  {
      x:278,
      y:162,
    },
    brenner:  {
      x:278,
      y:162,
    },
    hahnenkamm:  {
      x:278,
      y:162,
    },
    krimml:  {
      x:278,
      y:162,
    },
    rudolfshuette:  {
      x:278,
      y:162,
    },
    badgastein:  {
      x:278,
      y:162,
    },
    sonnblick:  {
      x:278,
      y:162,
    },
    stmichaellungau:  {
      x:278,
      y:162,
    },
    obertauern:  {
      x:278,
      y:162,
    },
    feuerkogel:  {
      x:278,
      y:162,
    },
    aigenimennstal:  {
      x:278,
      y:162,
    },
    stolzalpe:  {
      x:278,
      y:162,
    },
    zeltweg:  {
      x:278,
      y:162,
    },
    lunz:  {
      x:278,
      y:162,
    },
    mariazell:  {
      x:278,
      y:162,
    },
    fischbach:  {
      x:278,
      y:162,
    },
    raxseilbahn:  {
      x:278,
      y:162,
    },
    moenichkirchen:  {
      x:278,
      y:162,
    },
    mittelberg:  {
      x:278,
      y:162,
    },
    mallnitz:  {
      x:278,
      y:162,
    },
    villacheralpe:  {
      x:278,
      y:162,
    },
    preitenegg:  {
      x:278,
      y:162,
    },
    neumarktersattel:  {
      x:278,
      y:162,
    },
    schoeckl:  {
      x:278,
      y:162,
    },
    virgen:  {
      x:278,
      y:162,
    },
    mallnitzbad:  {
      x:278,
      y:162,
    },
    weissenseegatschach:  {
      x:278,
      y:162,
    },
    warth:  {
      x:278,
      y:162,
    },
    ischglidalpe:  {
      x:278,
      y:162,
    },
    galtuer:  {
      x:278,
      y:162,
    },
    holzgau:  {
      x:278,
      y:162,
    },
    stleonhardpitztal:  {
      x:278,
      y:162,
    },
    lofereralm:  {
      x:278,
      y:162,
    },
    schmittenhoehe:  {
      x:278,
      y:162,
    },
    rauris:  {
      x:278,
      y:162,
    },
    mariapfarr:  {
      x:278,
      y:162,
    },
    ramsaudachstein:  {
      x:278,
      y:162,
    },
    badaussee:  {
      x:278,
      y:162,
    },
    semmering:  {
      x:278,
      y:162,
    },
    hirschenkogelsemmering:  {
      x:278,
      y:162,
    },
    hohewand:  {
      x:278,
      y:162,
    },
    turracherhoehe:  {
      x:278,
      y:162,
    },
    hochschwabeismauer:  {
      x:278,
      y:162,
    },
    planneralmrotbuehel:  {
      x:278,
      y:162,
    },
    hohetauern:  {
      x:278,
      y:162,
    },
    unterkaernten:  {
      x:278,
      y:162,
    },
    lungau:  {
      x:278,
      y:162,
    },
    montafon:  {
      x:278,
      y:162,
    },
    flachau:  {
      x:278,
      y:162,
    },
    grossarl:  {
      x:278,
      y:162,
    },
    soelden:  {
      x:278,
      y:162,
    },
    kappl:  {
      x:278,
      y:162,
    },
    kaunertal:  {
      x:278,
      y:162,
    },
    pfunds:  {
      x:278,
      y:162,
    },
    see:  {
      x:278,
      y:162,
    },
    serfaus:  {
      x:278,
      y:162,
    },
    spiss:  {
      x:278,
      y:162,
    },
    lech:  {
      x:278,
      y:162,
    },
    damuels:  {
      x:278,
      y:162,
    },
    // bozen: {
    //   x: 278,
    //   y: 170,
    // },
    // innsbruck: {
    //   x: 275,
    //   y: 164,
    // },
    // lienz: {
    //   x: 285,
    //   y: 162,
    // },
  };

  private colorToDust = {
    "rgb(255,255,255)": "-",
    "rgb(238,229,204)": "1-10",
    "rgb(233,214,170)": "10-25",
    "rgb(233,187,146)": "25-50",
    "rgb(217,172,137)": "50-100",
    "rgb(217,146,113)": "100-500",
    "rgb(202,126,113)": "500-1000",
    "rgb(202,102,80)": ">1000",
  };

  private rgbToHex = {
    "rgb(255,255,255)": "#FFFFFF",
    "rgb(238,229,204)": "#EEE5CC",
    "rgb(233,214,170)": "#E9D6AA",
    "rgb(233,187,146)": "#E9BB92",
    "rgb(217,172,137)": "#D9AC89",
    "rgb(217,146,113)": "#D99271",
    "rgb(202,126,113)": "#CA7E71",
    "rgb(202,102,80)": "#CA6650",
  };

  constructor(private http: HttpClient) {}

  private readonly URL = "https://admin.avalanche.report/forecast.uoa.gr/0day/DUST/GRID1/zoomdload/%d.zoomdload.png";

  private loadForecast = (time: number): Promise<Blob> => {
    const paddedNumber = time.toString().padStart(3, "0");
    const url = this.URL.replace("%d", paddedNumber);
    const headers = new HttpHeaders({
      Accept: "image/avif,image/webp,*/*",
    });
    const response = this.http.get(url, {
      responseType: "blob",
      observe: "body",
      headers: headers,
    });

    return response.toPromise();
  };

  private createImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
      const objectURL = URL.createObjectURL(blob);
      const img = new Image();
      img.src = objectURL;
      img.onload = () => resolve(img);
    });
  };

  private getColorFromPx = (image: HTMLImageElement, x: number, y: number): Promise<number[]> => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.width = 1;
      canvas.height = 1;
      canvas.getContext("2d")?.drawImage(image, x, y, 1, 1, 0, 0, 1, 1);
      const rgba = [...canvas.getContext("2d")?.getImageData(0, 0, 1, 1).data];
      rgba.pop();
      resolve(rgba);
    });
  };

  private getDustFromColor = (rgb: number[]): Promise<string> => {
    return new Promise((resolve) => {
      const colorStr = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
      resolve(this.colorToDust[colorStr] ? this.colorToDust[colorStr] : "-");
    });
  };

  public dustToColor = (dust: string) => {
    const rgb = Object.entries(this.colorToDust).reduce((ret, entry) => {
      const [key, value] = entry;
      ret[value] = key;
      return ret;
    }, {});
    const hex = this.rgbToHex[rgb[dust]];
    return hex;
  };

  private getParamsForCity = (city: string): Array<Promise<string[]>> => {
    const nSteps = 35;
    const promises = [];

    const px = this.pxOfCity[city];

    for (let i = 0; i <= nSteps * 6; i += 6) {
      const imageBlobPromise = this.loadForecast(i);
      const dustPromise = imageBlobPromise
        .then((blob) => this.createImageFromBlob(blob))
        .then((image) => this.getColorFromPx(image, px.x, px.y))
        .then((rgb) => this.getDustFromColor(rgb))
        .catch((error) => {
          return new Promise((resolve) => {
            resolve("-");
          });
        });

      promises.push(dustPromise);
    }

    return promises;
  };

  public getDustParams = (): DustParams => {
    const dustParams: DustParams = {};
    for (const city of Object.keys(this.pxOfCity)) {
      const promises = this.getParamsForCity(city);
      dustParams[city] = promises;
    }
    return dustParams;
  };

  public parseDustParams = async () => {
    const promises = this.getDustParams();
    const dustForCity = {};
    for (const key of Object.keys(promises)) {
      const values = await Promise.all(promises[key]);
      const runs = [];
      while (values.length) runs.push(values.splice(0, 12));
      for (let i = 0; i < runs.length; i++) {
        const days = [];
        while (runs[i].length) {
          const day = runs[i].splice(0, 4);
          const parsedDay = {
            "00": day[0],
            "06": day[1],
            "12": day[2],
            "18": day[3],
          };
          days.push(parsedDay);
        }
        runs[i] = days;
      }
      dustForCity[key] = runs;
    }

    return dustForCity;
  };
}
