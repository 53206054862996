import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as types from "./qfa-types";
import { QfaFile } from "./qfa-file.model";
import { GetFilenamesService } from "./filenames.service";
import { GetDustParamService } from "./dust.service";
import { ParamService } from "./param.service";

export type QfaResult = {
  data: types.data;
  date: string;
  dates: string[];
  parameters: string[];
  file: any;
};

@Injectable()
export class QfaService {
  baseUrl = "https://static.lawinen-warnung.eu/zamg_qfa/";
  dustParams = {};
  coords = {
    jauerling: {
      lng:15.34,
      lat:48.33,
    },
    gaschurn: {
      lng:10.03,
      lat:46.99,
    },
    stantonarlberg: {
      lng:10.32,
      lat:47.13,
    },
    galzigarlberg: {
      lng:10.23,
      lat:47.13,
    },
    tannheim: {
      lng:10.51,
      lat:47.5,
    },
    nauders: {
      lng:10.5,
      lat:46.89,
    },
    ehrwald: {
      lng:10.92,
      lat:47.41,
    },
    patscherkofel: {
      lng:11.46,
      lat:47.21,
    },
    obergurgl: {
      lng:11.03,
      lat:46.87,
    },
    brenner: {
      lng:11.52,
      lat:47,
    },
    hahnenkamm: {
      lng:12.37,
      lat:47.42,
    },
    krimml: {
      lng:12.18,
      lat:47.23,
    },
    rudolfshuette: {
      lng:12.62,
      lat:47.13,
    },
    badgastein: {
      lng:13.13,
      lat:47.12,
    },
    sonnblick: {
      lng:12.9,
      lat:47.05,
    },
    stmichaellungau: {
      lng:13.65,
      lat:47.1,
    },
    obertauern: {
      lng:13.57,
      lat:47.27,
    },
    feuerkogel: {
      lng:13.73,
      lat:47.82,
    },
    aigenimennstal: {
      lng:14.13,
      lat:47.53,
    },
    stolzalpe: {
      lng:14.2,
      lat:47.12,
    },
    zeltweg: {
      lng:14.78,
      lat:47.2,
    },
    lunz: {
      lng:15,
      lat:47.85,
    },
    mariazell: {
      lng:15.32,
      lat:47.77,
    },
    fischbach: {
      lng:15.64,
      lat:47.44,
    },
    raxseilbahn: {
      lng:15.78,
      lat:47.72,
    },
    moenichkirchen: {
      lng:16.03,
      lat:47.52,
    },
    mittelberg: {
      lng:10.15,
      lat:47.32,
    },
    mallnitz: {
      lng:13.17,
      lat:46.99,
    },
    villacheralpe: {
      lng:13.67,
      lat:46.6,
    },
    preitenegg: {
      lng:14.92,
      lat:46.94,
    },
    neumarktersattel: {
      lng:14.4,
      lat:47.12,
    },
    schoeckl: {
      lng:15.47,
      lat:47.2,
    },
    virgen: {
      lng:12.47,
      lat:47,
    },
    mallnitzbad: {
      lng:13.18,
      lat:46.99,
    },
    weissenseegatschach: {
      lng:13.29,
      lat:46.72,
    },
    warth: {
      lng:10.19,
      lat:47.27,
    },
    ischglidalpe: {
      lng:10.32,
      lat:46.98,
    },
    galtuer: {
      lng:10.19,
      lat:46.97,
    },
    holzgau: {
      lng:10.33,
      lat:47.25,
    },
    stleonhardpitztal: {
      lng:10.87,
      lat:47.03,
    },
    lofereralm: {
      lng:12.7,
      lat:47.58,
    },
    schmittenhoehe: {
      lng:12.73,
      lat:47.33,
    },
    rauris: {
      lng:13,
      lat:47.25,
    },
    mariapfarr: {
      lng:13.75,
      lat:47.15,
    },
    ramsaudachstein: {
      lng:13.64,
      lat:47.43,
    },
    badaussee: {
      lng:13.78,
      lat:47.62,
    },
    semmering: {
      lng:15.83,
      lat:47.63,
    },
    hirschenkogelsemmering: {
      lng:15.83,
      lat:47.62,
    },
    hohewand: {
      lng:16.04,
      lat:47.82,
    },
    turracherhoehe: {
      lng:13.88,
      lat:46.92,
    },
    hochschwabeismauer: {
      lng:15.15,
      lat:47.62,
    },
    planneralmrotbuehel: {
      lng:14.21,
      lat:47.39,
    },
    hohetauern: {
      lng:13.17,
      lat:46.99,
    },
    unterkaernten: {
      lng:14.92,
      lat:46.94,
    },
    lungau: {
      lng:13.74,
      lat:47.15,
    },
    montafon: {
      lng:10.19,
      lat:47.26,
    },
    flachau: {
      lng:13.39,
      lat:47.34,
    },
    grossarl: {
      lng:13.2,
      lat:47.24,
    },
    soelden: {
      lng:11.01,
      lat:46.97,
    },
    kappl: {
      lng:10.38,
      lat:47.06,
    },
    kaunertal: {
      lng:10.75,
      lat:47.03,
    },
    pfunds: {
      lng:10.53,
      lat:46.97,
    },
    see: {
      lng:10.47,
      lat:47.08,
    },
    serfaus: {
      lng:10.61,
      lat:47.04,
    },
    spiss: {
      lng:10.43,
      lat:46.96,
    },
    lech: {
      lng:10.14,
      lat:47.21,
    },
    damuels: {
      lng:9.89,
      lat:47.28,
    },
    // innsbruck: {
    //   lng: 11.35,
    //   lat: 47.27,
    // },
    // lienz: {
    //   lng: 12.8,
    //   lat: 46.83,
    // },
  };
  cities: string[];
  files = {
  jauerling: [],
  gaschurn: [],
  stantonarlberg: [],
  galzigarlberg: [],
  tannheim: [],
  nauders: [],
  ehrwald: [],
  patscherkofel: [],
  obergurgl: [],
  brenner: [],
  hahnenkamm: [],
  krimml: [],
  rudolfshuette: [],
  badgastein: [],
  sonnblick: [],
  stmichaellungau: [],
  obertauern: [],
  feuerkogel: [],
  aigenimennstal: [],
  stolzalpe: [],
  zeltweg: [],
  lunz: [],
  mariazell: [],
  fischbach: [],
  raxseilbahn: [],
  moenichkirchen: [],
  mittelberg: [],
  mallnitz: [],
  villacheralpe: [],
  preitenegg: [],
  neumarktersattel: [],
  schoeckl: [],
  virgen: [],
  mallnitzbad: [],
  weissenseegatschach: [],
  warth: [],
  ischglidalpe: [],
  galtuer: [],
  holzgau: [],
  stleonhardpitztal: [],
  lofereralm: [],
  schmittenhoehe: [],
  rauris: [],
  mariapfarr: [],
  ramsaudachstein: [],
  badaussee: [],
  semmering: [],
  hirschenkogelsemmering: [],
  hohewand: [],
  turracherhoehe: [],
  hochschwabeismauer: [],
  planneralmrotbuehel: [],
  hohetauern: [],
  unterkaernten: [],
  lungau: [],
  montafon: [],
  flachau: [],
  grossarl: [],
  soelden: [],
  kappl: [],
  kaunertal: [],
  pfunds: [],
  see: [],
  serfaus: [],
  spiss: [],
  lech: [],
  damuels: [],
    // innsbruck: [],
    // bozen: [],
    // lienz: [],
  };

  constructor(
    public filenamesService: GetFilenamesService,
    public dustParamService: GetDustParamService,
    public paramService: ParamService,
    private http: HttpClient,
  ) {
    this.cities = Object.keys(this.files);
  }

  async loadDustParams() {
    this.dustParams = await this.dustParamService.parseDustParams();
  }

  async getFiles() {
    for (const city of this.cities) {
      const filenames = await this.filenamesService.getFilenames(this.baseUrl, city);
      const parsedFiles = [];
      for (const file of filenames) {
        const parsedFile = this.filenamesService.parseFilename(file.name);
        parsedFiles.push(parsedFile);
      }
      this.files[city] = parsedFiles.filter((el) => el.startDay === "00");
    }

    return this.files;
  }

  async getRun(file, startDay: number, first: boolean): Promise<QfaResult> {
    const days = `0${startDay}0${startDay + 2}`;
    const filename = file.filename.replace(/\d{4}\.txt/g, `${days}.txt`);
    const run = new QfaFile(this.http);
    await run.loadFromURL(filename);

    const parameters = Object.keys(run.data.parameters);

    if (first) {
      const city = run.data.metadata.location.split(" ")[2].toLowerCase();
      if (this.dustParams) {
        const dust = this.dustParams[city][startDay / 3];
        run.data.parameters["DUST"] = dust;
        parameters.unshift("DUST");
      }
    }

    const qfa = {
      data: run.data,
      date: run.date,
      dates: run.paramDates,
      parameters: parameters,
      file: file,
    };

    return qfa;
  }
}
